import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import { verify } from './apis/auth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Consulta = React.lazy(() => import('./views/pages/consulta/consulta'))

const verifyToken = async () => {
  try {
    if (!localStorage.getItem('session_token')) {
      return { isAuthenticated: false, role: null, permisos: null } // Si no hay un token almacenado, se considera inválido
    }

    const response = await verify(localStorage.getItem('session_token'))

    if (!response.success) {
      return { isAuthenticated: false, role: null, permisos: null }
    } else if (response.success) {
      const role = response.role
      const permisos = response.permisos

      return { isAuthenticated: true, role, permisos }
    } else {
      return { isAuthenticated: false, role: null, permisos: null }
    }
  } catch (error) {
    return { isAuthenticated: false, role: null, permisos: null }
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAuthenticated: false,
      role: null,
      permisos: null,
      isLoading: true,
    }
  }

  async componentDidMount() {
    this.actualizarAuth()

    // Añadir un listener para el evento personalizado
    window.addEventListener('actualizarAuth', this.actualizarAuth)
  }

  componentWillUnmount() {
    // Asegurarse de remover el listener cuando el componente se desmonta
    window.removeEventListener('actualizarAuth', this.actualizarAuth)
  }

  actualizarAuth = async () => {
    const { isAuthenticated, role, permisos } = await verifyToken()
    this.setState({ isAuthenticated, role, permisos, isLoading: false })
  }

  render() {
    const { isAuthenticated, role, permisos, isLoading } = this.state

    if (isLoading) {
      return loading
    }

    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/consulta" element={<Consulta />} />

            {isAuthenticated && role === 'admin' ? (
              <>
                <Route
                  path="*"
                  element={
                    <DefaultLayout
                      permisos={permisos}
                      isAuthenticated={isAuthenticated}
                      role={role}
                    ></DefaultLayout>
                  }
                />
                <Route path="/500" element={<Page500 />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/404" element={<Page404 />} />
                <Route path="/500" element={<Page500 />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
