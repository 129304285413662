import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASEURL
const database = process.env.REACT_APP_DATABASE
const email = localStorage.getItem('email')
const token = localStorage.getItem('session_token')

export async function login(email, password, database) {
  try {
    const response = await axios.post(`${baseUrl}/auth/login/${database}`, { email, password })
    return response.data
  } catch (error) {
    throw error
  }
}

export async function verify(token) {
  try {
    const response = await axios.get(`${baseUrl}/auth/verify/${database}/${email}/${token}`)
    return response.data
  } catch (error) {
    throw error
  }
}
